<template>
  <div class="subsection" ref="subsection">
    <div class="subsectionLeft"  v-if="fileSuffix === 'doc' || fileSuffix === 'docx' || fileSuffix === 'txt'">
      <doc-view
          ref="docView"
          :html_result="html_result"
          :split_paragraphs="split_paragraphs"
          :fileSuffix="fileSuffix"
          @selectDiv="selectDiv">
      </doc-view>
    </div>
    <pdf-view ref="pdfView" class="subsectionPdfLeft" id="subsectionLeft" :split_paragraphs="split_paragraphs" :ossPath="ossPath" v-else-if="fileSuffix === 'pdf'"></pdf-view>
    <div class="subsectionRight" @dragover="handleDragOver($event)">
      <template v-if="!loadingSplit">
        <div class="rightHeader">
          <div class="headerLeft">
            <span>{{ $t('versionManage.subsection.intelligentSplit') }}</span>
            <div class="number">{{split_paragraphs.length}}</div>
          </div>
          <div class="headerRight">
            <div class="sort">
              <div class="sortTitle">
                {{ $t('versionManage.subsection.sortTitle') }}
                <el-tooltip class="item" effect="dark" :content="$t('versionManage.subsection.sortTip')" placement="top">
                  <i class="iconfont guoran-bangzhuzhongxin"></i>
                </el-tooltip>
              </div>
              <el-switch
                  v-model="sortLock"
                  active-color="#366aff"
                  @change="changeSortLock"
              >
              </el-switch>
            </div>
            <div class="openBtn" @click="openOrClose('open')">
              <i class="iconfont guoran-kuozhan"></i>
              {{ $t('versionManage.subsection.expandAll') }}
            </div>
            <div class="closeBtn" @click="openOrClose('close')">
              <i class="iconfont guoran-tongyichicun-shouqi"></i>
              {{ $t('versionManage.subsection.collapseAll') }}
            </div>
            <div class="saveBtn" @click="saveParse">
              <i class="iconfont guoran-a-16-03"></i>
              {{ $t('versionManage.subsection.save') }}
            </div>
          </div>
        </div>
        <div class="splitParagraphs" id="splitParagraphs">
          <div class="paragraphItem"
               v-for="(item,index) in split_paragraphs"
               :key="index"
               :id="'paragraph' + index"
               @drop="handleDrop($event, index)"
               @dragenter="setBorderColor(index)"
               @dragleave="clearBorderColor(index)"
          >
            <div class="paragraphTitle">
              <div class="titleLeft">
                <div class="title">{{ $t('versionManage.subsection.section', { index: index + 1 }) }}</div>
                <span v-if="item.type === 'table'">{{ $t('versionManage.subsection.table') }}</span>
                <div class="prompt" v-else-if="item.original_paragraph && item.original_paragraph.length > 0 && item.original_paragraph[0].type !== 'image'">{{ item.original_paragraph[0].text }}</div>
                <div class="prompt" v-else>{{ $t('versionManage.subsection.image') }}</div>
              </div>
              <div class="titleRight">
                <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                   @click="editParagraphs(item,index)"
                   v-if="(editTableList[item.table_id] || editParagraphList[index]) && (!editStatusList[index] || !editStatusList[index].edit)"
                ></i>
                <i class="iconfont "
                   :class="statusList[index] && statusList[index].open ? 'guoran-tongyichicun-shouqi' : 'guoran-kuozhan'"
                   @click="setOpenClick(item,index)"
                ></i>
              </div>

            </div>
            <div class="paragraphContainer" v-show="statusList[index] && statusList[index].open">
              <template v-if="item.type !== 'table'">
                <template v-if="!editStatusList[index] || !editStatusList[index].edit">
                  <div class="paragraph" v-if="editParagraphList[index]">
                    <div class="text">{{ editParagraphList[index].paragraph }}</div>
                  </div>
                  <!--<div class="paragraph"
                       v-for="(items,indexs) in filterImages(item.images)"
                       :key="indexs">
                    <div class="image">
                      <el-image :src="items.oss_image_url" v-if="!items.ocr_image_text"></el-image>
                      <span v-else> {{items.ocr_image_text}} </span>
                    </div>
                  </div>-->
                </template>
                <template v-else>
                  <div class="editParagraph">
                    <div class="editHeader">
                      <div class="headerRight">
                        <i class="iconfont guoran-a-16-12" @click="paragraphFun(item,index,'reset')"></i>
                        <i class="iconfont guoran-tongyichicun-kaiqi1" @click="paragraphFun(item,index,'success')"></i>
                        <i class="iconfont guoran-shanchu" @click="paragraphFun(item,index,'cancel')"></i>
                      </div>
                    </div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 6, maxRows: 10 }"
                        placeholder="请输入内容"
                        v-model="editParagraphList[index].paragraph"
                        @change="changeParagraphList(index)"
                    >
                    </el-input>
                  </div>
                </template>
                <div class="originalParagraphs" :id="index + 'originalParagraphs'">
                  <div class="originalTitle">
                    {{ $t('versionManage.subsection.originalContent') }}
                    <div class="originalTip">
                      {{ $t('versionManage.subsection.originalTip') }}
                    </div>
                  </div>
                  <div class="originalParagraphList" :id="'originalParagraphList' + index">
                    <div class="originalParagraphItem"
                         v-for="(items,originalIndex) in item.original_paragraph"
                         :key="originalIndex"
                         :id="(items.paragraph_id || items.id) + originalIndex"
                         :draggable="item.original_paragraph.length > 1 && (!editStatusList[index] || !editStatusList[index].edit) ? true:false"
                         @dragstart="handleDragStart($event, index, originalIndex,items)"
                         @click.stop="goToLeft(items)"

                    >
                      <div class="originalLeft">
                        <i class="iconfont guoran-tuodong my-handle" v-if="(!editStatusList[index] || !editStatusList[index].edit) && ((index > 0 ||
                           (item.original_paragraph.length > 1 && originalIndex !== 0) ||
                           (index < split_paragraphs.length - 1 && split_paragraphs[index + 1].type !== 'table')))"></i>
                        <div class="originalText" v-if="!items.type">{{ items.text }}</div>
                        <div class="image" v-else>
                          <el-image :src="items.oss_image_url" v-if="!items.ocr_image_text" @click="onImageClick(items.oss_image_url)" style="height: 60px"></el-image>
                          <div v-else class="ocr_text">
                            <span class="ocr_icon" :style="{ color:iconColors[items.oss_image_url] }">[来源于图片]</span>
                            <div class="ocr_image_text" v-html="items.text"></div>
                          </div>
                        </div>
                      </div>
                      <div class="originalRight" :style="{display:visible[index + 'visible' + originalIndex] ? 'flex' : ''}">
                        <template v-if="items.type === 'image'">
                          <div class="ocrIcon" v-if="(!editStatusList[index] || !editStatusList[index].edit) && !items.is_deleted">
                            <i class="iconfont guoran-OCR" @click.stop="ocrFun(items,index,'image')" v-if="!items.ocr_image_text"></i>
                            <i class="iconfont guoran-tongyichicun-tupian2" @click.stop="ocrFun(items,index)" v-else :style="{ color:iconColors[items.oss_image_url] }"></i>
                          </div>
                          <div v-if="items.is_deleted" class="delete_icon">
                            {{ $t('versionManage.subsection.isDeleted') }}
                          </div>
                        </template>
                        <el-popover
                            placement="bottom"
                            trigger="hover"
                            v-model="visible[index + 'visible' + originalIndex]"
                            popper-class="commitLoadMore"
                            content=""
                        >
                          <div class="popoverContainer" v-if="items.type !== 'image'">
                            <div class="popoverItem" @click="operate('pre',items,index,originalIndex)" v-if="index > 0">
                              <span>{{ $t('versionManage.subsection.moveToPreSection') }}</span>
                            </div>
                            <div class="popoverItem" @click="operate('next',items,index,originalIndex)" v-if="index < split_paragraphs.length - 1 && split_paragraphs[index + 1].type !== 'table'">
                              <span>{{ $t('versionManage.subsection.moveToNextSection') }}</span>
                            </div>
                            <div class="popoverItem" v-if="item.original_paragraph.length > 1 && originalIndex !== 0" @click="operate('new',items,index,originalIndex)">
                              <span>{{ $t('versionManage.subsection.newSection') }}</span>
                            </div>
                          </div>
                          <div class="popoverContainer" v-else>
                            <template v-if="!items.is_deleted">
                              <div class="popoverItem" @click="operate('pre',items,index,originalIndex,'image')" v-if="index > 0">
                                <span>{{ $t('versionManage.subsection.moveToPreSection') }}</span>
                              </div>
                              <div class="popoverItem" @click="operate('next',items,index,originalIndex,'image')" v-if="index < split_paragraphs.length - 1 && split_paragraphs[index + 1].type !== 'table'">
                                <span>{{ $t('versionManage.subsection.moveToNextSection') }}</span>
                              </div>
                              <div class="popoverItem" v-if="item.original_paragraph.length > 1 && originalIndex !== 0" @click="operate('new',items,index,originalIndex,'image')">
                                <span>{{ $t('versionManage.subsection.newSection') }}</span>
                              </div>
                              <div class="popoverItem" v-if="!items.is_deleted" @click="deleteImage(items,index,originalIndex)">
                                <span>{{ $t('versionManage.subsection.delete') }}</span>
                              </div>
                            </template>
                            <div class="popoverItem" v-else-if="items.is_deleted" @click="restoreImage(items,index,originalIndex)">
                              <span>{{ $t('versionManage.subsection.restore') }}</span>
                            </div>
                          </div>
                          <i slot="reference" class="iconfont guoran-qita1"
                             v-show="(!editStatusList[index] || !editStatusList[index].edit) && ((index > 0 ||
                           (item.original_paragraph.length > 1 && originalIndex !== 0) ||
                           (index < split_paragraphs.length - 1 && split_paragraphs[index + 1].type !== 'table')))"></i>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'table'">
                <template v-if="!editStatusList[index] || !editStatusList[index].edit">
                  <div class="table">
                    <el-table
                        :data="tableData[item.table_id]"
                        border
                        ref="table"
                        style="width: 100%"
                        :empty-text="'暂无表格内容，您可以根据需要自行填写'"
                    >
                      <template v-for="(item,index) in headerArray[item.table_id]">
                        <el-table-column
                            :label="item.name"
                            :key="index"
                            min-width="100">
                          <template slot-scope="scope">
                            {{scope.row[item.id]}}
                          </template>
                        </el-table-column>
                      </template>
                    </el-table>
                  </div>
                </template>
                <template v-else>
                  <div class="editParagraph">
                    <div class="editHeader">
                      <div class="headerRight">
                        <i class="iconfont guoran-a-16-12" @click="paragraphFun(item,index,'reset',true)"></i>
                        <el-popover
                            placement="bottom"
                            width="200"
                            trigger="manual"
                            v-model="tableVisible[item.table_id]">
                          <div class="popoverContainer">
                            <div class="tips">当前编辑未保存,是否保存?</div>
                            <div class="popoverBtn">
                              <div class="same cancel" @click="paragraphFun(item,index,'cancel',true)">不保存并退出</div>
                              <div class="same confirm" @click="paragraphFun(item,index,'success',true)">保存</div>
                            </div>
                          </div>
                          <i class="iconfont guoran-tongyichicun-kaiqi1" @click="paragraphFun(item,index,'success',true)" slot="reference"></i>
                        </el-popover>
                        <i class="iconfont guoran-shanchu" @click="paragraphFun(item,index,'cancel',true)"></i>
                      </div>
                    </div>
                    <div v-if="editTableList[item.table_id] && editTableList[item.table_id].edit_previous_paragraph_text" class="editContainer">
                      <div class="tableHeader">
                        表格前内容
                        <div class="tableHeaderTip">
                          该部分内容为原文中表格的上文，可供大模型更好识别表格
                        </div>
                      </div>
                      <el-input
                          type="textarea"
                          :autosize="{ minRows: 3, maxRows: 6 }"
                          placeholder="请输入内容"
                          v-model="editTableList[item.table_id].edit_previous_paragraph_text"
                          @change="changeTableParagraph(item)"
                      >
                      </el-input>
                    </div>
                    <div class="editContainer tableContainer" :id="'table' + item.table_id">
                      <div class="tableHeader">
                        表格内容
                      </div>
                      <el-input
                          type="textarea"
                          :autosize="{ minRows: 6, maxRows: 10 }"
                          placeholder="请输入内容"
                          v-model="editTableList[item.table_id].edit_table_markdown_text"
                          @input="changeTableDown(item,index)"
                      >
                      </el-input>
                      <div class="tablePopover" :id="'popover' + item.table_id">
                        <el-table
                            :data="tableData[item.table_id]"
                            border
                            ref="table"
                            height="100%"
                            style="width: 100%"
                            :empty-text="'暂无表格内容，您可以根据需要自行填写'"
                        >
                          <template v-for="(item,index) in headerArray[item.table_id]">
                            <el-table-column
                                :label="item.name"
                                :key="index"
                                min-width="100">
                              <template slot-scope="scope">
                                {{scope.row[item.id]}}
                              </template>
                            </el-table-column>
                          </template>
                        </el-table>
                      </div>
                    </div>
                    <div v-if="editTableList[item.table_id] && editTableList[item.table_id].edit_next_paragraph_text" class="editContainer">
                      <div class="tableHeader">
                        表格后内容
                        <div class="tableHeaderTip">
                          该部分内容为原文中表格的下文，可供大模型更好识别表格
                        </div>
                      </div>
                      <el-input
                          type="textarea"
                          :autosize="{ minRows: 3, maxRows: 6 }"
                          placeholder="请输入内容"
                          v-model="editTableList[item.table_id].edit_next_paragraph_text"
                          @change="changeTableParagraph(item)"
                      >
                      </el-input>
                    </div>
                  </div>
                </template>
                <div class="originalParagraphs">
                  <div class="originalTitle">
                    {{ $t('versionManage.subsection.originalContent') }}
                    <div class="originalTip">
                      {{ $t('versionManage.subsection.originalTip') }}
                    </div>
                  </div>
                  <div class="originalParagraphList" :id="'originalParagraphList' + index">
                    <div class="originalParagraphItem"
                         v-for="(items,originalIndex) in item.original_paragraph"
                         :key="originalIndex"
                    >
                      <div class="originalLeft">
                        <div class="table" v-html="items.html"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <div class="loading" v-else>
        <img src="../../../assets/images/loading.gif">
        {{ $t('versionManage.subsection.splitting') }}
      </div>
    </div>
    <popup-com @closeEvent="ocrPopup = false" v-if="ocrPopup" :hideBool="hideBool">
      <div slot="popup-name" v-if="!hideBool">{{ $t('versionManage.subsection.warn') }}</div>
      <div slot="popup-con" class="orcTips">
        <div class="tip1">
          <img src="../../../assets/images/loading.gif" v-if="hideBool">
          {{ identifyMessage1 }}
        </div>
        <div class="tip1">{{ identifyMessage2 }}</div>
      </div>
      <div slot="dialog-footer" v-if="!hideBool">
        <el-button  plain size="small" @click="ocrPopup = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmOcr"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.confirm') }}</el-button>
      </div>
    </popup-com>
    <div v-if="showPreview">
      <custom-img-view
          :src="ocrImage"
          :loading="loading"
          :ocrText="ocrText"
          :showOcrBtn="showOcrBtn"
          @closeViewer="closeViewer"
          @identifyOcr="identifyOcr"
      ></custom-img-view>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import pdfView from "@/views/versionManage/components/pdfView";
import { v4 as uuidv4 } from "uuid";
import { requestUrl } from "@/http/requestUrl";
import PopupCom from "@/components/popup";
import DocView from "@/views/versionManage/components/docView";
import CustomImgView from "@/components/customImgView";
export default {
  name: "subsectionCom",
  data(){
    return{
      html:"",
      "split_paragraphs":[],
      tables:[],
      draggedItem: null,  // 当前拖动的文本块
      draggedItemIndex: null,  // 当前拖动的文本块索引
      colors: ['#E3EBFF', '#FFF5DC', '#FFE8D8','#D6F3EA','#FFF1C3'],
      iconColorList:["#366aff","#bf0000","#ffaaff"],
      iconColors:{},
      goRightId:null,
      visible:{},
      parseInfo:{},
      split_paragraphs_copy:"[]",
      fileSuffix:"docx",
      statusList:{},
      editStatusList:{},
      html_result:"",
      loadingSplit:true,
      identifyMessage1:"",
      identifyMessage2:"",
      ocrPopup:false,
      hideBool:false,
      identifyType:"",
      originalType:"",
      showPreview:false,
      ocrImage:"",
      ocrText:"OCR识别图片内容",
      loading:false,
      ocrIndex:0,
      ocrId:"",
      ocrType:"",
      unrelated_images:[],
      editParagraphList:{},
      sortLock:true,
      sortable:{},
      showOcrBtn:true,
      checkStatus:{}, //编辑之后分段操作提示
      drop_split_paragraphs_copy:"",//拖动前记录下当前的分段情况
      edit_paragraph:{},
      ocr_image_text:"",
      editTableList:{},
      tablePopover:{},
      tableData:{},//记录表格行数据
      headerArray:{},//记录表格表头
      editTableId:"",//当前正在编辑的表格id
      tableVisible:{},
      editIndex:"", //编辑表格时记录下标，当前正在编辑用与滚动到指定编辑位置
      editTableListCopy:null,//编辑前保存点击x的时候用与恢复编辑前内容
      paragraph_reset:{},
      edit_table:{}
    }
  },
  props:{
    ossPath:{
      type:String,
      default:""
    }
  },
  components:{
    CustomImgView,
    DocView,
    PopupCom,
    pdfView
  },
  methods:{
    setLeftHtmlDiv(){
      for (let i=0;i<this.split_paragraphs.length;i++){
        const dom = document.createElement('div');
        dom.setAttribute('class', 'leftParagraphItem');
        for (let j=0;j<this.split_paragraphs[i].original_paragraph.length;j++){
          let id = this.split_paragraphs[i].original_paragraph[j].paragraph_id;
          let domItem = document.getElementById(id);
          if (domItem){
            dom.appendChild(domItem);
          }
          // this.$refs.subsectionLeft.replaceChild(dom, this.$refs.subsectionLeft);
        }
        this.$refs.subsectionLeft.appendChild(dom)
      }

    },
    async getParseInfo(){
      let url = requestUrl.correction.parseInfo + this.$route.query.knowledgeId;
      await this.$http.get(url).then(async res => {
        if (res.data.code == 0 && res.data.data){
          this.parseInfo = res.data.data;
          if (res.data.data.extractChunkInfo && res.data.data.extractChunkInfo.docChunkData){
            if (res.data.data.extractChunkInfo.docChunkData.paragraphs){
              if (res.data.data.extractChunkInfo.docChunkData.paragraphs.split_paragraphs){
                this.split_paragraphs = res.data.data.extractChunkInfo.docChunkData.paragraphs.split_paragraphs;
              } else if (res.data.data.extractChunkInfo.docChunkData.paragraphs){
                this.split_paragraphs = res.data.data.extractChunkInfo.docChunkData.paragraphs;
              }
            }
            this.html_result = res.data.data.extractChunkInfo.docChunkData.html_result;
            if (res.data.data.extractChunkInfo.docChunkData.tables && res.data.data.extractChunkInfo.docChunkData.tables.length > 0){
              this.tables = res.data.data.extractChunkInfo.docChunkData.tables;
            }
            if (res.data.data.extractChunkInfo.docChunkData.unrelated_images){
              this.unrelated_images = res.data.data.extractChunkInfo.docChunkData.unrelated_images
            }
          }
        }
        let arr = []


        for (let i =0 ;i <this.tables.length;i++){
          if (this.tables[i].table_html){
            let obj = {
              type:'table',
              html:this.tables[i].table_html,
              paragraph:"",
              original_paragraph:[
                {
                  html:this.tables[i].table_html,
                  position:this.tables[i].bbox,
                  page:this.tables[i].page
                }
              ],
              paragraph_id:uuidv4(),
              position:this.tables[i].bbox,
              table_id:this.tables[i].table_id,
              page:this.tables[i].page
            }
            let edit_table_markdown = this.tables[i].edit_table_markdown ? this.tables[i].edit_table_markdown : this.tables[i].table_markdown;
            let edit_table_text = edit_table_markdown.table_header;
            if (edit_table_markdown.table_header){
              const index = edit_table_markdown.table_header.indexOf('| --- |');
              if (index !== -1) {
                edit_table_text = edit_table_markdown.table_header.substring(0, index)
              }
            }
            for (let t =0 ;t<edit_table_markdown.table_data.length;t++){
              let row_data_md = JSON.parse(JSON.stringify(edit_table_markdown.table_data[t].row_data_md)).replaceAll('\n',"")
              edit_table_text += '\n' + row_data_md
            }
            let editTableObj = {
              edit_next_paragraph_text:this.tables[i].edit_next_paragraph_text ? this.tables[i].edit_next_paragraph_text : this.tables[i].next_paragraph_text,
              edit_previous_paragraph_text:this.tables[i].edit_previous_paragraph_text ? this.tables[i].edit_previous_paragraph_text : this.tables[i].previous_paragraph_text,
              edit_table_markdown_text:edit_table_text,
              table_markdown:this.tables[i].table_markdown,
              edit_table_markdown:this.tables[i].edit_table_markdown ? this.tables[i].edit_table_markdown : this.tables[i].table_markdown,
              next_paragraph_text:this.tables[i].next_paragraph_text,
              previous_paragraph_text:this.tables[i].previous_paragraph_text
            }
            this.$set(this.editTableList,this.tables[i].table_id,editTableObj);
            this.setDownTable(this.tables[i])
            arr.push(obj)
          }
          if (this.tables[i].tables_html){
            for (let i=0;i<this.tables[i].tables_html.length;i++){
              let obj = {
                type:'table',
                html:this.tables[i].tables_html[i],
                paragraph:"",
                original_paragraph:[
                  {
                    html:this.tables[i].tables_html[i],
                    position:this.tables[i].bbox,
                    page:this.tables[i].page
                  }
                ],
                paragraph_id:uuidv4(),
                position:this.tables[i].bbox,
                page:this.tables[i].page
              }
              arr.push(obj)
            }
          }
        }


        this.split_paragraphs = this.split_paragraphs.concat(arr);

        if (this.unrelated_images.length > 0){
          this.unrelated_images = this.unrelated_images.map(img => {
            img.type = 'image';
            img.source = "unrelated_images"
            return img
          })
          let obj = {
            original_paragraph:this.unrelated_images,
            images:[],
            paragraph:""
          }
          this.split_paragraphs.push(obj)
        }
        this.split_paragraphs = this.split_paragraphs.filter(item => (item.original_paragraph.length > 0))
        await this.processAssociatedImage();
        this.split_paragraphs = this.split_paragraphs.map(item => {
          item.original_paragraph = item.original_paragraph.filter(items => items.text || items.type === 'image' || items.html)
          return item
        })
        this.split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
        for (let i=0;i<this.split_paragraphs.length;i++){
          if (this.split_paragraphs[i].edit_paragraph){
            this.$set(this.editParagraphList,i,{paragraph:this.split_paragraphs[i].edit_paragraph})
          } else if (this.split_paragraphs[i].paragraph){
            this.$set(this.editParagraphList,i,{paragraph:this.split_paragraphs[i].paragraph})
          }
        }
        this.loadingSplit = false;
        this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
      })
    },
    processAssociatedImage(){
      /* 把关联图片拿出来，为了单行显示一个图片。保存时需要将关联图片再push到关联到文字中 */
      for (let i=0;i<this.split_paragraphs.length;i++){
        if (this.split_paragraphs[i].original_paragraph){
          for (let j=0;j<this.split_paragraphs[i].original_paragraph.length;j++){
            let item = this.split_paragraphs[i].original_paragraph[j];
            if (item.images && item.images.length > 0) {
              for (let k = 0;k<item.images.length;k++){
                let original_paragraph = [];
                let imagesItem = item.images[k];
                /* 新更改的ocr_image_text要取ocr返回之后的全部值 */
                if (imagesItem.ocr_image_text && imagesItem.ocr_image_text.split_paragraphs){
                  for (let m = 0;m < imagesItem.ocr_image_text.split_paragraphs.length; m++){
                    let new_original_paragraph = []
                    for (let o = 0;o<imagesItem.ocr_image_text.split_paragraphs[m].original_paragraph.length;o++){
                      let obj = {
                        oss_image_url:imagesItem.oss_image_url,
                        id:imagesItem.id,
                        is_deleted:imagesItem.is_deleted,
                        type:"image",
                        ocr_image_text:imagesItem.ocr_image_text ? JSON.parse(JSON.stringify(imagesItem.ocr_image_text)) : null,
                        text:imagesItem.ocr_image_text.split_paragraphs[m].original_paragraph[o].text
                      }
                      new_original_paragraph.push(obj)
                    }
                    original_paragraph = original_paragraph.concat(new_original_paragraph)
                  }
                  this.split_paragraphs[i].original_paragraph.splice(j + 1,0,...original_paragraph)
                } else if (imagesItem.ocr_image_text && imagesItem.ocr_image_text.tables){
                  let tables = []
                  for (let m = 0;m < imagesItem.ocr_image_text.tables.length; m++){
                    let obj = {
                      oss_image_url:imagesItem.oss_image_url,
                      id:imagesItem.id,
                      is_deleted:imagesItem.is_deleted,
                      type:"image",
                      ocr_image_text:imagesItem.ocr_image_text ? JSON.parse(JSON.stringify(imagesItem.ocr_image_text)) : null,
                      text:imagesItem.ocr_image_text.tables[m].table_html
                    }
                    tables.push(obj)
                  }
                  original_paragraph = original_paragraph.concat(tables)
                  this.split_paragraphs[i].original_paragraph.splice(j + 1,0,...original_paragraph)
                } else {
                  imagesItem.type = 'image';
                  imagesItem.text = imagesItem.ocr_image_text;
                  this.split_paragraphs[i].original_paragraph.splice(j + 1,0,imagesItem)
                }
                const colorIndex = j % this.iconColorList.length;
                this.iconColors[imagesItem.oss_image_url] = this.iconColorList[colorIndex]
              }
              item.images = [];
            }
          }
        }
      }
    },
    saveParse(type){
      let url = requestUrl.correction.saveParseInfo;
      let split_paragraphs = this.split_paragraphs.filter(item => item.type !== 'table');
      let unrelated_images = [];
      for (let k in this.editParagraphList){
        if (this.edit_paragraph[k]){
          split_paragraphs[k].edit_paragraph = this.editParagraphList[k].paragraph
        } else if (this.paragraph_reset[k]){
          split_paragraphs[k].edit_paragraph = null;
        }
      }
      for (let i=0;i<split_paragraphs.length;i++){
        for (let j=0;j<split_paragraphs[i].original_paragraph.length;j++){
          let item = split_paragraphs[i].original_paragraph[j];
          if (item.type === 'image'){
            if (j == 0){
              if (item.source === 'unrelated_images'){
                unrelated_images.push(item)
              } else {
                split_paragraphs[i].original_paragraph[j] = {
                  images:[item],
                  title:"",
                  text:"",
                  paragraph_id:uuidv4(),
                  paragraph:""
                }
              }
            } else {
              let ids = [];
              if (split_paragraphs[i].original_paragraph[j-1].text && split_paragraphs[i].original_paragraph[j-1].images){
                ids = split_paragraphs[i].original_paragraph[j-1].images.map(img => img.id)
                if (ids.indexOf(item.id) == -1){
                  split_paragraphs[i].original_paragraph[j-1].images.push(item);
                }
              } else if (item.source === 'unrelated_images'){
                unrelated_images.push(item)
              } else {
                split_paragraphs[i].original_paragraph[j] = {
                  images:[item],
                  title:"",
                  text:"",
                  paragraph_id:uuidv4(),
                  paragraph:""
                }
              }
              split_paragraphs[i].original_paragraph.splice(j,1);
              j = j-1;
            }
          }
        }
      }
      split_paragraphs = split_paragraphs.map(item => {
        item.original_paragraph = item.original_paragraph.filter(items => items.type !== 'image')
        return item
      })
      for (let i=0;i<this.tables.length;i++){
        if (this.edit_table[this.tables[i].table_id]){
          if (this.editTableList[this.tables[i].table_id].edit_next_paragraph_text){
            this.tables[i].edit_next_paragraph_text = this.editTableList[this.tables[i].table_id].edit_next_paragraph_text;
          }
          if (this.editTableList[this.tables[i].table_id].edit_previous_paragraph_text){
            this.tables[i].edit_previous_paragraph_text = this.editTableList[this.tables[i].table_id].edit_previous_paragraph_text;
          }
          if (this.editTableList[this.tables[i].table_id].edit_table_markdown){
            this.tables[i].edit_table_markdown = this.editTableList[this.tables[i].table_id].edit_table_markdown;
          }
        }
      }
      if (this.parseInfo.extractChunkInfo.docChunkData.paragraphs.split_paragraphs){
        this.parseInfo.extractChunkInfo.docChunkData.paragraphs.split_paragraphs = split_paragraphs.filter(item => item.original_paragraph.length > 0);
      } else {
        this.parseInfo.extractChunkInfo.docChunkData.paragraphs = split_paragraphs.filter(item => item.original_paragraph.length > 0);
      }
      this.parseInfo.extractChunkInfo.docChunkData.unrelated_images = unrelated_images;
      this.parseInfo.extractChunkInfo.docChunkData.tables = this.tables
      this.$http.post(url,this.parseInfo).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('versionManage.subsection.saveSuccess'),
            type:"success"
          })
          this.checkStatus = {};
          this.editStatusList = {};
          this.edit_paragraph = {};
          this.edit_table = {};
          this.editTableId = ""
          // this.split_paragraphs_copy = JSON.stringify(this.split_paragraphs)
          this.getParseInfo();
        }
        if (type === 'back'){
          this.$parent.goBack();
        }
      })
    },
    goBack(){
      // 记录的 从其他系统跳转过来的信息
      if (sessionStorage.getItem('toVersionManage')) {
        this.$router.push({ path: '/knowledge_Management' })
        sessionStorage.removeItem('toVersionManage')
      } else {
        this.$router.push({ path: '/knowledge_Management', query: { isback: 1 } })
      }
    },
    sortableList(index){
      let _this = this;
      let id = 'originalParagraphList' + index
      let el = document.getElementById(id);
      this.sortable[index] = new Sortable(el, {
        animation: 200,
        easing: "cubic-bezier(1, 0, 0, 1)",
        handle: ".my-handle",
        sort:this.sortLock ? false : true,
        onUpdate: function (event) {
          if (_this.checkStatus[index] == undefined &&
              _this.editParagraphList[index] &&
              _this.editParagraphList[index].paragraph !== _this.split_paragraphs[index].paragraph){
            _this.$confirm(_this.$t('versionManage.subsection.sourceParagraph'), _this.$t('versionManage.subsection.confirmTitle'), {
              confirmButtonText: _this.$t('common.confirm'),
              cancelButtonText: _this.$t('common.cancel'),
              type: 'warning'
            }).then(() => {
              _this.checkStatus[index] = true;
              _this.editParagraphList[index].paragraph = _this.split_paragraphs[index].paragraph;
              _this.edit_paragraph[index] = false;
              //修改items数据顺序
              let newIndex = event.newIndex,
                  oldIndex = event.oldIndex,
                  $li = el.children[newIndex],
                  $oldLi = el.children[oldIndex];
              // 先删除移动的节点
              el.removeChild($li)
              // 再插入移动的节点到原有节点，还原了移动的操作
              if (newIndex > oldIndex) {
                el.insertBefore($li, $oldLi)
              } else {
                el.insertBefore($li, $oldLi.nextSibling)
              }
              // 更新items数组
              let item = _this.split_paragraphs[index].original_paragraph.splice(oldIndex, 1)
              _this.split_paragraphs[index].original_paragraph.splice(newIndex, 0, item[0]);
              _this.drop_split_paragraphs_copy = JSON.stringify(_this.split_paragraphs);
            }).catch(() => {
              _this.checkStatus[index] = undefined;
              _this.split_paragraphs = JSON.parse(_this.drop_split_paragraphs_copy);
            })
          } else {
            //修改items数据顺序
            let newIndex = event.newIndex,
                oldIndex = event.oldIndex,
                $li = el.children[newIndex],
                $oldLi = el.children[oldIndex];
            // 先删除移动的节点
            el.removeChild($li)
            // 再插入移动的节点到原有节点，还原了移动的操作
            if (newIndex > oldIndex) {
              el.insertBefore($li, $oldLi)
            } else {
              el.insertBefore($li, $oldLi.nextSibling)
            }
            // 更新items数组
            let item = _this.split_paragraphs[index].original_paragraph.splice(oldIndex, 1)
            _this.split_paragraphs[index].original_paragraph.splice(newIndex, 0, item[0]);
            _this.drop_split_paragraphs_copy = JSON.stringify(_this.split_paragraphs);
          }
        },
      });
    },
    setColor(){
      this.split_paragraphs.forEach((item,index) => {
        const colorIndex = index % this.colors.length;
        if (item.original_paragraph){
          item.original_paragraph.forEach(items => {
            let dom = document.getElementById(items.paragraph_id);
            if (dom){
              dom.style.background = this.colors[colorIndex];
            }
          })
          let newOriginal = item.original_paragraph.filter(items => !items.type)
          let dom = document.getElementById(newOriginal[newOriginal.length - 1].paragraph_id);
          if (dom){
            dom.style.marginBottom = '8px';
          }
        }
      })
    },
    editParagraphs(item,index){
      if (this.editTableId){
        let id = 'paragraph' + this.editIndex;
        let dom = document.getElementById(id);
        if (dom){
          dom.scrollIntoView({ behavior: 'smooth' });
        }
        this.$set(this.tableVisible,this.editTableId,true)
        return
      }
      if (this.editStatusList[index] && this.editStatusList[index].edit){
        this.$set(this.editStatusList[index],'edit',false)
      } else {
        this.$set(this.editStatusList,index,{ edit:true })
        this.$set(this.statusList,index,{ open:true })
      }
      if (item.table_id){
        let id = 'table' + item.table_id;
        this.editTableId = item.table_id;
        this.editIndex = index;
        this.editTableListCopy = JSON.stringify(this.editTableList[item.table_id])
        this.$nextTick(() => {
          let height = document.getElementById(id).clientHeight;
          let popoverId = 'popover' + item.table_id;
          document.getElementById(popoverId).style.top = height + 100 + 'px'
        })
      }
    },
    setOpenClick(item,index){
      if (this.statusList[index] && this.statusList[index].open){
        this.$set(this.statusList[index],'open',false)
      } else {
        this.$set(this.statusList,index,{ open:true })
        this.$nextTick(() => {
          if (this.sortable[index]){
            if (this.sortLock){
              this.sortable[index].option('sort',false)
            } else {
              this.sortable[index].option('sort',true)
            }
          } else {
            this.sortableList(index);
          }
        })
      }
    },
    paragraphFun(item,index,type,isTable){
      if (isTable){
        this.tablePopover[item.table_id] = false;
        this.editTableId = "";
        this.tableVisible[item.table_id] = false;
        if (type === 'success'){
          this.$set(this.editStatusList[index],'edit',false)
          // item.edit_next_paragraph_text = this.editTableList[item.table_id].edit_next_paragraph_text;
          // item.edit_previous_paragraph_text = this.editTableList[item.table_id].edit_previous_paragraph_text;
          this.setDownTable(item,'success')
        } else if (type == 'reset'){
          this.$set(this.editStatusList[index],'edit',false)
          this.$set(this.edit_paragraph,index,false)
          this.edit_table[item.table_id] = false;
          if (item.edit_next_paragraph_text){
            delete item.edit_next_paragraph_text
          }
          if (item.edit_previous_paragraph_text){
            delete item.edit_previous_paragraph_text
          }
          if (item.edit_table_markdown){
            delete item.edit_table_markdown
          }
          let edit_table_markdown = this.editTableList[item.table_id].table_markdown;
          let edit_table_text = edit_table_markdown.table_header;
          if (edit_table_markdown.table_header){
            const index = edit_table_markdown.table_header.indexOf('| --- |');
            if (index !== -1) {
              edit_table_text = edit_table_markdown.table_header.substring(0, index)
            }
          }
          for (let t =0 ;t<edit_table_markdown.table_data.length;t++){
            let row_data_md = JSON.parse(JSON.stringify(edit_table_markdown.table_data[t].row_data_md)).replaceAll('\n',"")
            edit_table_text += '\n' + row_data_md
          }
          this.editTableList[item.table_id].edit_next_paragraph_text = this.editTableList[item.table_id].next_paragraph_text;
          this.editTableList[item.table_id].edit_previous_paragraph_text = this.editTableList[item.table_id].previous_paragraph_text;
          this.editTableList[item.table_id].edit_table_markdown_text = edit_table_text;
          this.setDownTable(item)
        } else {
          this.$set(this.editStatusList[index],'edit',false);
          if (this.editTableListCopy){
            this.editTableList[item.table_id] = JSON.parse(this.editTableListCopy);
          }
          this.setDownTable(item)
        }
        return
      }
      this.checkStatus[index] = undefined
      if (type === 'success'){
        this.$set(this.editStatusList[index],'edit',false)
      } else if (type === 'reset'){
        this.$set(this.editStatusList[index],'edit',false)
        this.editParagraphList[index].paragraph = this.split_paragraphs[index].paragraph;
        this.$set(this.edit_paragraph,index,false)
        this.$set(this.paragraph_reset,index,true)
      } else {
        this.editParagraphList[index].paragraph = this.split_paragraphs[index].paragraph;
        this.$set(this.editStatusList[index],'edit',false)
      }
    },
    tableRowData(item,headerData,excelRowList){
      this.$set(this.tableData,item.table_id,[])
      this.$set(this.headerArray,item.table_id,[])
      const headerString = headerData.trim().replace(/^\||\|$/g, '');
      // 使用竖线作为分隔符来分割字符串
      const headerArray = headerString.split('|').map(item => item.trim());

      for (let i =0;i<headerArray.length;i++){
        let obj = {
          name:headerArray[i],
          id:uuidv4()
        }
        this.headerArray[item.table_id].push(obj)
      }
      for (let i =0;i<excelRowList.length;i++){
        // 去除字符串两端的竖线（如果它们存在的话）
        const trimmedString = excelRowList[i].row_data_md.trim().replace(/^\||\|$/g, '');

        // 使用竖线作为分隔符来分割字符串
        let resultArray = trimmedString.split('|').map(item => item.trim());
        // resultArray = resultArray.filter(res => res)
        let obj = {};
        for (let j=0;j<this.headerArray[item.table_id].length;j++){
          if (resultArray[j]){
            this.$set(obj,this.headerArray[item.table_id][j].id,resultArray[j])
          }
        }
        this.tableData[item.table_id].push(obj);
      }
    },
    setDownTable(item,type){
      let arr = this.editTableList[item.table_id].edit_table_markdown_text.split('\n');
      arr = arr.filter(data => data);
      let table_data = []
      for (let i=1;i<arr.length;i++){
        let obj = {
          row_data_md:arr[i],
          row_id:uuidv4(),
          row_number:null,
          table_html:null
        }
        table_data.push(obj)
      }
      let tableDown = {
        table_header:arr[0],
        table_data
      }
      if (type !== 'success'){
        this.tableRowData(item,tableDown.table_header,tableDown.table_data)
        if (type == 'change'){
          this.editTableList[item.table_id].edit_table_markdown = tableDown;
        }
      } else {
        // item.edit_table_markdown = tableDown;
        this.editTableList[item.table_id].edit_table_markdown = tableDown;
      }
    },
    // 拖动开始
    handleDragStart(event, splitIndex, originalIndex,items) {
      this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
      this.draggedItemIndex = { splitIndex, originalIndex };
      this.originalType = items.type
      event.dataTransfer.setData("text/plain", `${splitIndex}-${originalIndex}`);
    },

    // 拖动过程中
    handleDragOver(event) {
      event.preventDefault();  // 必须阻止默认事件才能允许 drop 事件
    },
    setBorderColor(index){
      if (index !== this.draggedItemIndex.splitIndex){
        let domId = 'paragraph' + index;
        let dom = document.getElementById(domId);
        if (dom){
          dom.style.borderColor = '#366aff'
        }
      }
    },
    clearBorderColor(index){
      let domId = 'paragraph' + index;
      let dom = document.getElementById(domId);
      if (dom){
        dom.style.borderColor = '#ffffff'
      }
    },
    // 拖动放下
    handleDrop(event, targetSplitIndex, targetOriginalIndex) {
      const source = this.draggedItemIndex;  // 被拖动的项的来源
      const target = { splitIndex: targetSplitIndex, originalIndex: targetOriginalIndex };
      if (this.split_paragraphs[target.splitIndex].type === 'table'){
        return
      }
      if (this.sortLock && source.splitIndex !== target.splitIndex && (target.splitIndex + 1 == source.splitIndex || target.splitIndex - 1 == source.splitIndex)) {
        this.dropParagraphs(targetSplitIndex,targetOriginalIndex)
      } else if (!this.sortLock && source.splitIndex !== target.splitIndex){
        this.dropParagraphs(targetSplitIndex,targetOriginalIndex)
      }
    },
    dropParagraphs(targetSplitIndex, targetOriginalIndex){
      const source = this.draggedItemIndex;  // 被拖动的项的来源
      const target = { splitIndex: targetSplitIndex, originalIndex: targetOriginalIndex };
      const newOriginalIndex = source.originalIndex + 1;
      let attr = 'original_paragraph';
      let item = [];
      if (target.splitIndex + 1 == source.splitIndex){
        item = this.split_paragraphs[source.splitIndex][attr].splice(0, newOriginalIndex);
        this.$set(this.split_paragraphs[target.splitIndex], attr, this.split_paragraphs[target.splitIndex][attr].concat(item));
      } else if (target.splitIndex - 1 == source.splitIndex){
        item = this.split_paragraphs[source.splitIndex][attr].splice(source.originalIndex, this.split_paragraphs[source.splitIndex][attr].length);
        this.$set(this.split_paragraphs[target.splitIndex], attr, item.concat(this.split_paragraphs[target.splitIndex][attr]));
      }
      let id = '';
      let clearBottomDomId = ''
      if (item.length > 0 && this.split_paragraphs.length > 0){
        if (target.splitIndex + 1 == source.splitIndex){
          id = item[item.length - 1].paragraph_id + (this.split_paragraphs[target.splitIndex].original_paragraph.length - item.length);
          if (this.split_paragraphs[target.splitIndex].original_paragraph.length - 1 - item.length >= 0){
            clearBottomDomId = this.split_paragraphs[target.splitIndex].original_paragraph[this.split_paragraphs[target.splitIndex].original_paragraph.length - 1 - item.length].paragraph_id
          }
        } else {
          id = target.splitIndex + 'originalParagraphs';
          clearBottomDomId = item[item.length - 1].paragraph_id
        }
      }
      let sourceParagraphBool = this.checkStatus[source.splitIndex] == undefined &&
          this.editParagraphList[source.splitIndex] &&
          this.editParagraphList[source.splitIndex].paragraph !== this.split_paragraphs[source.splitIndex].paragraph;
      let targetParagraphBool = this.checkStatus[target.splitIndex] == undefined &&
          this.editParagraphList[target.splitIndex] &&
          this.editParagraphList[target.splitIndex].paragraph !== this.split_paragraphs[target.splitIndex].paragraph;
      let messageTitle = this.$t('versionManage.subsection.sourceParagraph')
      if (sourceParagraphBool && targetParagraphBool){
        messageTitle = this.$t('versionManage.subsection.sourceAndTarget')
      } else if (targetParagraphBool){
        messageTitle = this.$t('versionManage.subsection.targetParagraph')
      }
      if (sourceParagraphBool || targetParagraphBool){
        this.$confirm(messageTitle, this.$t('versionManage.subsection.confirmTitle'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          if (sourceParagraphBool && targetParagraphBool){
            this.checkStatus[source.splitIndex] = true;
            this.checkStatus[target.splitIndex] = true
          } else if (sourceParagraphBool){
            this.checkStatus[source.splitIndex] = true;
          } else if (targetParagraphBool){
            this.checkStatus[target.splitIndex] = true
          }
          if (!this.statusList[target.splitIndex]){
            this.$set(this.statusList,target.splitIndex,{ open:false })
          }
          this.$set(this.statusList[target.splitIndex],'open',true)
          if (this.sortable[target.splitIndex]){
            if (this.sortLock){
              this.sortable[target.splitIndex].option('sort',false)
            } else {
              this.sortable[target.splitIndex].option('sort',true)
            }
          } else {
            this.sortableList(target.splitIndex);
          }
          // 删除源段落的 item.text
          const sourceParagraph = this.split_paragraphs[source.splitIndex];
          if (item.length > 0){
            let paragraph = ""
            for (let i=0;i<item.length;i++){
              // 合并 item.text 到目标段落的 paragraph 字段中
              if (item[i].text){
                paragraph += item[i].text
                sourceParagraph.paragraph = sourceParagraph.paragraph.replace(item[i].text, '').trim();
              }
            }
            if (target.splitIndex - 1 == source.splitIndex){
              this.split_paragraphs[target.splitIndex].paragraph = paragraph + this.split_paragraphs[target.splitIndex].paragraph
            } else {
              this.split_paragraphs[target.splitIndex].paragraph += paragraph;
            }
            this.$set(this.editParagraphList,source.splitIndex,{paragraph:sourceParagraph.paragraph})
            this.$set(this.editParagraphList,target.splitIndex,{paragraph:this.split_paragraphs[target.splitIndex].paragraph})
          }
          this.edit_paragraph[source.splitIndex] = false;
          // 如果目标段落的 original_paragraph 为空，删除该段落
          if (this.split_paragraphs[source.splitIndex].original_paragraph.length === 0) {
            this.split_paragraphs.splice(source.splitIndex, 1);
          }
          //添加完元素滚动到指定位置
          setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
            const dom = document.getElementById(clearBottomDomId);
            if (dom){
              dom.style.marginBottom = '0'
            }
            if (this.fileSuffix !== 'pdf'){
              this.$refs.docView.setColor();
            }
          },300)
          this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
        }).catch(() => {
          if (sourceParagraphBool && targetParagraphBool){
            this.checkStatus[source.splitIndex] = undefined;
            this.checkStatus[target.splitIndex] = undefined
          } else if (sourceParagraphBool){
            this.checkStatus[source.splitIndex] = undefined;
          } else if (targetParagraphBool){
            this.checkStatus[target.splitIndex] = undefined
          }
          this.split_paragraphs = JSON.parse(this.drop_split_paragraphs_copy);
        });
      } else {
        if (!this.statusList[target.splitIndex]){
          this.$set(this.statusList,target.splitIndex,{ open:false })
        }
        this.$set(this.statusList[target.splitIndex],'open',true)
        if (this.sortable[target.splitIndex]){
          if (this.sortLock){
            this.sortable[target.splitIndex].option('sort',false)
          } else {
            this.sortable[target.splitIndex].option('sort',true)
          }
        } else {
          this.sortableList(target.splitIndex);
        }
        // 删除源段落的 item.text
        const sourceParagraph = this.split_paragraphs[source.splitIndex];
        if (item.length > 0){
          let paragraph = ""
          for (let i=0;i<item.length;i++){
            // 合并 item.text 到目标段落的 paragraph 字段中
            if (item[i].text){
              paragraph += item[i].text
              sourceParagraph.paragraph = sourceParagraph.paragraph.replace(item[i].text, '').trim();
            }
          }
          if (target.splitIndex - 1 == source.splitIndex){
            this.split_paragraphs[target.splitIndex].paragraph = paragraph + this.split_paragraphs[target.splitIndex].paragraph
          } else {
            this.split_paragraphs[target.splitIndex].paragraph += paragraph;
          }
          this.$set(this.editParagraphList,source.splitIndex,{paragraph:sourceParagraph.paragraph})
          this.$set(this.editParagraphList,target.splitIndex,{paragraph:this.split_paragraphs[target.splitIndex].paragraph})
        }
        this.edit_paragraph[source.splitIndex] = false;
        // 如果目标段落的 original_paragraph 为空，删除该段落
        if (this.split_paragraphs[source.splitIndex].original_paragraph.length === 0) {
          this.split_paragraphs.splice(source.splitIndex, 1);
        }
        //添加完元素滚动到指定位置
        setTimeout(() => {
          const el = document.getElementById(id);
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
          const dom = document.getElementById(clearBottomDomId);
          if (dom){
            dom.style.marginBottom = '0'
          }
          if (this.fileSuffix !== 'pdf'){
            this.$refs.docView.setColor();
          }
        },300)
        this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
      }
    },
    selectDiv(e){
      this.goRightId = null;
      if (e.target.parentNode){
        if (e.target.parentNode.id){
          for (let i=0;i<this.split_paragraphs.length;i++){
            if (this.split_paragraphs[i].original_paragraph){
              for (let j=0;j<this.split_paragraphs[i].original_paragraph.length;j++){
                if (this.split_paragraphs[i].original_paragraph[j].paragraph_id == e.target.parentNode.id){
                  this.goRightId = {i,j};
                  if (!this.statusList[i]){
                    this.$set(this.statusList,i,{ open:false })
                  }
                  this.$set(this.statusList[i],'open',true);
                  setTimeout(() => {
                    let id = 'paragraph' + i
                    const el = document.getElementById(id);
                    if (el) {
                      el.scrollIntoView({ behavior: 'smooth' });
                      this.$nextTick(() => {
                        el.style.animation = 'blink-border 3s infinite'
                      })
                    }
                    if (this.sortable[i]){
                      if (this.sortLock){
                        this.sortable[i].option('sort',false)
                      } else {
                        this.sortable[i].option('sort',true)
                      }
                    } else {
                      this.sortableList(i);
                    }
                  })
                }
              }
            }
          }
        } else if (e.target.parentNode.parentNode.id){
          for (let i=0;i<this.split_paragraphs.length;i++){
            if (this.split_paragraphs[i].original_paragraph){
              for (let j=0;j<this.split_paragraphs[i].original_paragraph.length;j++){
                if (this.split_paragraphs[i].original_paragraph[j] && this.split_paragraphs[i].original_paragraph[j].paragraph_id == e.target.parentNode.parentNode.id){
                  this.goRightId = {i,j};
                  if (!this.statusList[i]){
                    this.$set(this.statusList,i,{ open:false })
                  }
                  this.$set(this.statusList[i],'open',true);
                  setTimeout(() => {
                    let id = 'paragraph' + i
                    const el = document.getElementById(id);
                    if (el) {
                      el.scrollIntoView({ behavior: 'smooth' });
                      this.$nextTick(() => {
                        el.style.animation = 'blink-border 3s infinite'
                      })
                    }
                    if (this.sortable[i]){
                      if (this.sortLock){
                        this.sortable[i].option('sort',false)
                      } else {
                        this.sortable[i].option('sort',true)
                      }
                    } else {
                      this.sortableList(i);
                    }
                  },300)

                }
              }
            }
          }
        }
      }
    },
    async goToLeft(item){
      let id = item.paragraph_id;
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        this.$nextTick(() => {
          el.classList.add('animation');
          setTimeout(() => {
            el.classList.remove('animation')
          },3000)
        })
      } else if (!item.paragraph_id && item.type !== 'image'){
        await this.$refs.pdfView.loadPage(item.page);
        setTimeout(() => {
          this.$refs.pdfView.scrollToHighlight({ pageNumber:item.page,position:item.position })
        },300)
      }
    },
    openOrClose(type){
      this.split_paragraphs.forEach((item,index) => {
        if (!this.statusList[index]){
          this.$set(this.statusList,index,{ open:false })
        }
        if (type === 'open'){
          this.$set(this.statusList[index],'open',true)
        } else {
          this.$set(this.statusList[index],'open',false)
        }
        this.$nextTick(() => {
          if (this.sortable[index]){
            if (this.sortLock){
              this.sortable[index].option('sort',false)
            } else {
              this.sortable[index].option('sort',true)
            }
          } else {
            this.sortableList(index);
          }
        })
      })
    },
    changeSortLock(){
      for (let i=0;i<this.split_paragraphs.length;i++){
        if (this.statusList[i] && this.statusList[i].open){
          if (this.sortable[i]){
            if (this.sortLock){
              this.sortable[i].option('sort',false)
            } else {
              this.sortable[i].option('sort',true)
            }
          } else {
            this.sortableList(i);
          }
        }
      }
    },
    operate(type,item,index,originalIndex){
      let list = [];
      let goToIndex = 0;
      let attr = 'original_paragraph';
      if (type === 'pre'){
        list = this.split_paragraphs[index][attr].splice(0, originalIndex + 1);
        goToIndex = index - 1;
        this.$set(this.split_paragraphs[index - 1], attr, this.split_paragraphs[index - 1][attr].concat(list));
      } else{
        list = this.split_paragraphs[index][attr].splice(originalIndex, this.split_paragraphs[index][attr].length);
        goToIndex = index + 1;
        if (type === 'next'){
          this.$set(this.split_paragraphs[index + 1], attr, list.concat(this.split_paragraphs[index + 1][attr]));
        } else {
          let obj = {
            paragraph :'',
            original_paragraph:list,
            images:[]
          }
          this.split_paragraphs.splice(index + 1, 0, obj);
        }
      }

      this.visible[index + 'visible' + originalIndex] = false;
      let sourceParagraphBool = this.checkStatus[index] == undefined &&
          this.editParagraphList[index] &&
          this.editParagraphList[index].paragraph !== this.split_paragraphs[index].paragraph;
      let targetParagraphBool = this.checkStatus[goToIndex] == undefined &&
          this.editParagraphList[goToIndex] &&
          this.editParagraphList[goToIndex].paragraph !== this.split_paragraphs[goToIndex].paragraph && type !== 'new';
      let messageTitle = this.$t('versionManage.subsection.sourceParagraph')
      if (sourceParagraphBool && targetParagraphBool){
        messageTitle = this.$t('versionManage.subsection.sourceAndTarget')
      } else if (targetParagraphBool){
        messageTitle = this.$t('versionManage.subsection.targetParagraph')
      }
      if (sourceParagraphBool || targetParagraphBool){
        this.$confirm(messageTitle, this.$t('versionManage.subsection.confirmTitle'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          if (sourceParagraphBool && targetParagraphBool){
            this.checkStatus[index] = true;
            this.checkStatus[goToIndex] = true
            this.edit_paragraph[index] = false;
            this.edit_paragraph[goToIndex] = false;
          } else if (sourceParagraphBool){
            this.checkStatus[index] = true;
            this.edit_paragraph[index] = false;
          } else if (targetParagraphBool){
            this.checkStatus[goToIndex] = true
            this.edit_paragraph[goToIndex] = false;
          }
          // 删除源段落的 item.text
          const sourceParagraph = this.split_paragraphs[index];
          let paragraph = ""
          if (list.length > 0){
            for (let i=0;i<list.length;i++){
              // 合并 item.text 到目标段落的 paragraph 字段中
              if (list[i].text){
                paragraph += list[i].text
                sourceParagraph.paragraph = sourceParagraph.paragraph.replace(list[i].text, '').trim();
              }
            }
            if (type !== 'pre'){
              this.split_paragraphs[goToIndex].paragraph = paragraph + this.split_paragraphs[goToIndex].paragraph
            } else {
              this.split_paragraphs[goToIndex].paragraph += paragraph;
            }
          }
          if (!this.statusList[goToIndex]){
            this.$set(this.statusList,goToIndex,{ open:false })
          }
          this.statusList[goToIndex].open = true;
          let id = ''
          if (type === 'pre'){
            id = this.split_paragraphs[goToIndex].original_paragraph[this.split_paragraphs[goToIndex].original_paragraph.length - list.length].paragraph_id + (this.split_paragraphs[goToIndex].original_paragraph.length - list.length);
          } else {
            id = goToIndex + 'originalParagraphs';
          }
          let clearBottomDomId = ''
          if (this.split_paragraphs[goToIndex][attr].length - 1 - list.length >= 0){
            if (type === 'pre'){
              clearBottomDomId = this.split_paragraphs[goToIndex].original_paragraph[this.split_paragraphs[goToIndex].original_paragraph.length - 1 - list.length].paragraph_id
            } else {
              clearBottomDomId = this.split_paragraphs[goToIndex].original_paragraph[list.length - 1].paragraph_id
            }
          }
          //添加完元素滚动到指定位置
          setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
            const dom = document.getElementById(clearBottomDomId);
            if (dom){
              dom.style.marginBottom = '0'
            }
            if (this.fileSuffix !== 'pdf'){
              this.$refs.docView.setColor();
            }
            // 如果目标段落的 original_paragraph 为空，删除该段落
            if (this.split_paragraphs[index].original_paragraph.length === 0) {
              this.split_paragraphs.splice(index, 1);
            }
            for (let i=0;i<this.split_paragraphs.length;i++){
              if (!this.edit_paragraph[i]){
                this.$set(this.editParagraphList,i,{paragraph:this.split_paragraphs[i].paragraph})
              }
            }
            if (type === 'new'){
              this.sortableList(this.split_paragraphs.length - 1);
            } else if (this.sortable[goToIndex]){
              if (this.sortLock){
                this.sortable[goToIndex].option('sort',false)
              } else {
                this.sortable[goToIndex].option('sort',true)
              }
            } else {
              this.sortableList(goToIndex);
            }
          },300)
          this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
        }).catch(() => {
          if (sourceParagraphBool && targetParagraphBool){
            this.checkStatus[index] = undefined;
            this.checkStatus[goToIndex] = undefined
          } else if (sourceParagraphBool){
            this.checkStatus[index] = undefined;
          } else if (targetParagraphBool){
            this.checkStatus[goToIndex] = undefined
          }
          this.split_paragraphs = JSON.parse(this.drop_split_paragraphs_copy);
        });
      } else {
        if (!this.statusList[goToIndex]){
          this.$set(this.statusList,goToIndex,{ open:false })
        }
        this.statusList[goToIndex].open = true;
        // 删除源段落的 item.text
        const sourceParagraph = this.split_paragraphs[index];
        let paragraph = ""
        if (list.length > 0){
          for (let i=0;i<list.length;i++){
            // 合并 item.text 到目标段落的 paragraph 字段中
            if (list[i].text){
              paragraph += list[i].text
              sourceParagraph.paragraph = sourceParagraph.paragraph.replace(list[i].text, '').trim();
            }
          }
          if (type !== 'pre'){
            this.split_paragraphs[goToIndex].paragraph = paragraph + this.split_paragraphs[goToIndex].paragraph
          } else {
            this.split_paragraphs[goToIndex].paragraph += paragraph;
          }
        }
        this.edit_paragraph[index] = false;
        let id = ''
        if (type === 'pre'){
          id = this.split_paragraphs[goToIndex].original_paragraph[this.split_paragraphs[goToIndex].original_paragraph.length - list.length].paragraph_id + (this.split_paragraphs[goToIndex].original_paragraph.length - list.length);
        } else {
          id = goToIndex + 'originalParagraphs';
        }
        let clearBottomDomId = ''
        if (this.split_paragraphs[goToIndex][attr].length - 1 - list.length >= 0){
          if (type === 'pre'){
            clearBottomDomId = this.split_paragraphs[goToIndex].original_paragraph[this.split_paragraphs[goToIndex].original_paragraph.length - 1 - list.length].paragraph_id
          } else {
            clearBottomDomId = this.split_paragraphs[goToIndex].original_paragraph[list.length - 1].paragraph_id
          }
        }
        //添加完元素滚动到指定位置
        setTimeout(() => {
          const el = document.getElementById(id);
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
          const dom = document.getElementById(clearBottomDomId);
          if (dom){
            dom.style.marginBottom = '0'
          }
          if (this.fileSuffix !== 'pdf'){
            this.$refs.docView.setColor();
          }
          // 如果目标段落的 original_paragraph 为空，删除该段落
          if (this.split_paragraphs[index].original_paragraph.length === 0) {
            this.split_paragraphs.splice(index, 1);
          }
          for (let i=0;i<this.split_paragraphs.length;i++){
            if (!this.edit_paragraph[i]){
              this.$set(this.editParagraphList,i,{paragraph:this.split_paragraphs[i].paragraph})
            }
          }
          if (type === 'new'){
            this.sortableList(this.split_paragraphs.length - 1);
          } else if (this.sortable[goToIndex]){
            if (this.sortLock){
              this.sortable[goToIndex].option('sort',false)
            } else {
              this.sortable[goToIndex].option('sort',true)
            }
          } else {
            this.sortableList(goToIndex);
          }
        },300)
        this.drop_split_paragraphs_copy = JSON.stringify(this.split_paragraphs);
      }
    },
    deleteImage(items,index){
      this.$confirm(this.$t('versionManage.subsection.confirmDelete'), this.$t('versionManage.subsection.delete'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$set(items,'is_deleted',true)
        for (let i=0;i<this.split_paragraphs[index].original_paragraph.length;i++){
          if (this.split_paragraphs[index].original_paragraph[i].type === 'image' && this.split_paragraphs[index].original_paragraph[i].ocr_image_text){
            if ((this.split_paragraphs[index].original_paragraph[i].id && (this.split_paragraphs[index].original_paragraph[i].id === items.id)) || this.split_paragraphs[index].original_paragraph[i].oss_image_url === items.oss_image_url){
              this.split_paragraphs[index].paragraph = this.split_paragraphs[index].paragraph.replace(this.split_paragraphs[index].original_paragraph[i].text,"");
              this.editParagraphList[index].paragraph = this.editParagraphList[index].paragraph.replace(this.split_paragraphs[index].original_paragraph[i].text,"");
            }
            if (this.split_paragraphs[index].original_paragraph[i].oss_image_url === items.oss_image_url){
              this.split_paragraphs[index].original_paragraph[i].is_deleted = true;
            }
          }
        }
        this.$message({
          message: this.$t('versionManage.subsection.deleteSuccess'),
          type:"success"
        })
      }).catch(() => {

      })
    },
    restoreImage(items,index){
      this.$confirm(this.$t('versionManage.subsection.confirmRestore'), this.$t('versionManage.subsection.restore'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$set(items,'is_deleted',false)
        for (let i=0;i<this.split_paragraphs[index].original_paragraph.length;i++){
          if (this.split_paragraphs[index].original_paragraph[i].type === 'image' && this.split_paragraphs[index].original_paragraph[i].ocr_image_text){
            let indexStr = -1;
            let str = ""
            if (i > 0 && ((this.split_paragraphs[index].original_paragraph[i].id && (this.split_paragraphs[index].original_paragraph[i].id === items.id)) || this.split_paragraphs[index].original_paragraph[i].oss_image_url === items.oss_image_url)){
              str = this.split_paragraphs[index].original_paragraph[i - 1].text
            }
            if (this.split_paragraphs[index].original_paragraph[i].oss_image_url === items.oss_image_url){
              this.split_paragraphs[index].original_paragraph[i].is_deleted = false;
            }
            indexStr = this.split_paragraphs[index].paragraph.indexOf(str)
            if (indexStr !== -1 && this.split_paragraphs[index].paragraph){
              this.split_paragraphs[index].paragraph = this.split_paragraphs[index].paragraph.substring(0, indexStr + 1) + this.split_paragraphs[index].original_paragraph[i].text + this.split_paragraphs[index].paragraph.substring(indexStr + 1)
              this.editParagraphList[index].paragraph = this.editParagraphList[index].paragraph.substring(0, indexStr + 1) + this.split_paragraphs[index].original_paragraph[i].text + this.editParagraphList[index].paragraph.substring(indexStr + 1)
            }
          }
        }
        this.$message({
          message: this.$t('versionManage.subsection.restoreSuccess'),
          type:"success"
        })
      }).catch(() => {

      })
    },
    filterImages(images){
      return images.filter(item => !item.is_deleted)
    },
    identify(type){
      this.identifyMessage1 = '';
      this.identifyMessage2 = '';
      this.identifyType = type;
      if (type === 'img'){
        this.identifyMessage1 = this.$t('versionManage.subsection.confirmOcrAll');
        this.identifyMessage2 = this.$t('versionManage.subsection.ocrTip');
      } else {
        this.identifyMessage1 = this.$t('versionManage.subsection.confirmRestoreAll');
        this.identifyMessage2 = this.$t('versionManage.subsection.restoreAllTip');
      }
      this.ocrPopup = true;
    },
    confirmOcr(){
      // this.ocrPopup = false;
      this.hideBool = true;
      if (this.identifyType === 'img'){
        this.identifyMessage1 = this.$t('versionManage.subsection.ocrSplitting');
      } else {
        this.identifyMessage1 = this.$t('versionManage.subsection.restoreSplitting');
      }
      this.identifyMessage2 = this.$t('versionManage.subsection.operationTip');
    },
    ocrFun(item,index,type){
      if (this.checkStatus[this.ocrIndex] == undefined &&
          this.editParagraphList[this.ocrIndex] &&
          this.editParagraphList[this.ocrIndex].paragraph !== this.split_paragraphs[this.ocrIndex].paragraph){
        this.$confirm(this.$t('versionManage.subsection.sourceParagraph'), this.$t('versionManage.subsection.confirmTitle'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          this.checkStatus[this.ocrIndex] = true;
          this.ocrType = type;
          if (type === 'image'){
            this.ocrImage = item.oss_image_url;
            this.ocrId = item.id;
            this.ocrText = this.$t('versionManage.subsection.ocrImage');
          } else {
            this.ocrImage = item.oss_image_url;
            this.ocrId = item.id;
            this.ocrText = this.$t('versionManage.subsection.restoreImage');
          }
          this.ocrIndex = index;
          this.showOcrBtn = true;
          this.showPreview = true;
          this.editParagraphList[index].paragraph = this.split_paragraphs[index].paragraph;
          this.edit_paragraph[index] = false;
        }).catch(() => {
          this.checkStatus[this.ocrIndex] = undefined;
          this.split_paragraphs = JSON.parse(this.drop_split_paragraphs_copy);
        })
      } else {
        this.ocrType = type;
        if (type === 'image'){
          this.ocrImage = item.oss_image_url;
          this.ocrId = item.id;
          this.ocrText = this.$t('versionManage.subsection.ocrImage');
        } else {
          this.ocrImage = item.oss_image_url;
          this.ocrId = item.id;
          this.ocrText = this.$t('versionManage.subsection.restoreImage');
        }
        this.ocrIndex = index;
        this.showOcrBtn = true;
        this.showPreview = true;
      }

    },
    closeViewer(){
      this.showPreview = false;
    },
    identifyOcr(){
      this.loading = true;
      if (this.ocrType === 'image'){
        this.ocrText = this.$t('versionManage.subsection.recognizing');
        let url = '/ocr-api/document/ocr_recognition?document_id=' + this.$route.query.knowledgeId + '&oss_address=' + this.ocrImage;
        this.$http.post(url).then(res => {
          let paragraph = ''
          if (res.data.status === 'success'){
            this.showPreview = false;
            this.loading = false;
            let data = JSON.stringify(res.data)
            if (res.data.split_paragraphs || res.data.tables){
              for (let i=0;i<res.data.split_paragraphs.length;i++){
                let item = res.data.split_paragraphs[i];
                paragraph += item.paragraph
              }
              this.ocr_image_text = paragraph;
              let str = '';
              let index = -1
              for (let k=0;k<this.split_paragraphs[this.ocrIndex].original_paragraph.length;k++){
                let img = this.split_paragraphs[this.ocrIndex].original_paragraph[k];
                if (img.type === 'image' && ((img.id && img.id === this.ocrId) || img.oss_image_url === this.ocrImage)){
                  let original_paragraph = [];
                  for (let i=0;i<res.data.split_paragraphs.length;i++){
                    let new_original_paragraph = res.data.split_paragraphs[i].original_paragraph.map(item => {
                      let obj = {
                        oss_image_url:img.oss_image_url,
                        id:img.id,
                        is_deleted:img.is_deleted,
                        type:"image",
                        ocr_image_text:JSON.parse(data),
                        text:item.text
                      }
                      return obj
                    })
                    original_paragraph = original_paragraph.concat(new_original_paragraph)
                  }
                  for (let i=0;i<res.data.tables.length;i++){
                    let table = res.data.tables.map(tab => {
                      let obj = {
                        oss_image_url:img.oss_image_url,
                        id:img.id,
                        is_deleted:img.is_deleted,
                        type:"image",
                        ocr_image_text:JSON.parse(data),
                        text:tab.html
                      }
                      return obj
                    })
                    original_paragraph = original_paragraph.concat(table)
                  }
                  this.split_paragraphs[this.ocrIndex].original_paragraph.splice(k,1);
                  this.split_paragraphs[this.ocrIndex].original_paragraph.splice(k,0,...original_paragraph)
                  if (k == 0){
                    str = this.split_paragraphs[this.ocrIndex].original_paragraph[k].text
                  } else {
                    str = this.split_paragraphs[this.ocrIndex].original_paragraph[k-1].text
                  }
                }
                index = this.split_paragraphs[this.ocrIndex].paragraph.indexOf(str);
              }
              if (index !== -1 && this.split_paragraphs[this.ocrIndex].paragraph){
                let newIndex = index + str.length
                this.split_paragraphs[this.ocrIndex].paragraph = this.split_paragraphs[this.ocrIndex].paragraph.substring(0, newIndex + 1) + paragraph + this.split_paragraphs[this.ocrIndex].paragraph.substring(newIndex + 1)
                if (!this.editParagraphList[this.ocrIndex]){
                  this.$set(this.editParagraphList,this.ocrIndex,{paragraph:""})
                }
                this.editParagraphList[this.ocrIndex].paragraph = this.editParagraphList[this.ocrIndex].paragraph.substring(0, newIndex + 1) + paragraph + this.editParagraphList[this.ocrIndex].paragraph.substring(newIndex + 1)
              } else {
                this.split_paragraphs[this.ocrIndex].paragraph += paragraph;
                if (!this.editParagraphList[this.ocrIndex]){
                  this.$set(this.editParagraphList,this.ocrIndex,{paragraph:""})
                }
                this.editParagraphList[this.ocrIndex].paragraph += paragraph;
              }
              this.$message({
                message: this.$t('versionManage.subsection.ocrSuccess'),
                type:"success"
              })
            } else {
              this.$message({
                message: this.$t('versionManage.subsection.noRecognizableText'),
                type:"warning"
              })
            }
          } else {
            this.loading = false;
            this.showPreview = false;
            this.$message({
              message: this.$t('versionManage.subsection.recognizableFailText'),
              type:"warning"
            })
          }
        }).catch(() => {
          this.loading = false;
          this.showPreview = false;
          this.$message({
            message: this.$t('versionManage.subsection.recognizableFailText'),
            type:"warning"
          })
        })
      } else {
        this.ocrText = this.$t('versionManage.subsection.restoringAndSplitting');
        for (let i=0;i<this.split_paragraphs[this.ocrIndex].original_paragraph.length;i++){
          let item = this.split_paragraphs[this.ocrIndex].original_paragraph[i];
          if ((item.id && item.id == this.ocrId) || item.oss_image_url === this.ocrImage){
            this.ocr_image_text = item.text;
            item.ocr_image_text = ""
            item.text = "";
          }
        }
        this.split_paragraphs[this.ocrIndex].original_paragraph = this.split_paragraphs[this.ocrIndex].original_paragraph.filter((original,index,self) => {
          if (original.type === 'image'){
            return self.findIndex(t => (t.oss_image_url === original.oss_image_url)) === index;
          }
          return original
        })
        this.showPreview = false;
        this.loading = false;
        if (this.split_paragraphs[this.ocrIndex]){
          this.split_paragraphs[this.ocrIndex].paragraph = this.split_paragraphs[this.ocrIndex].paragraph.replace(this.ocr_image_text,"")
        }
        if (this.editParagraphList[this.ocrIndex]){
          this.editParagraphList[this.ocrIndex].paragraph = this.editParagraphList[this.ocrIndex].paragraph.replace(this.ocr_image_text,"")
        }
        this.$message({
          message: this.$t('versionManage.subsection.restoreComplete'),
          type:"success"
        })
      }
    },
    onImageClick(url){
      this.ocrImage = url;
      this.showPreview = true;
      this.showOcrBtn = false;
    },
    changeParagraphList(index){
      this.paragraph_reset[index] = false;
      this.edit_paragraph[index] = true;
    },
    changeTableDown(item,index){
      this.edit_paragraph[index] = true;
      this.edit_table[item.table_id] = true;
      this.setDownTable(item,'change');
    },
    changeTableParagraph(item){
      this.edit_table[item.table_id] = true;
    }
  },
  mounted() {
    this.fileSuffix = this.$route.query.fileSuffix
    this.getParseInfo();
  },
};
</script>

<style scoped lang="less">
.subsection{
  height: 100%;
  width: calc(100% - 40px);
  display: flex;
  box-sizing: border-box;
  background: #ffffff;
  .subsectionLeft{
    width: 50%;
    flex: none;
    border-right: 1px solid #E0E6F7;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: scroll;
    div{
      position: relative;
    }
    ///deep/div:hover{
    //  background: #F4F6FB!important;
    //}
    .leftParagraphItem:hover{
      background: #F4F6FB!important;
      cursor: pointer;
    }
    /deep/img{
      max-width: 100%;
    }
  }
  .subsectionPdfLeft{
    width: 50%;
    flex: none;
    border-right: 1px solid #E0E6F7;
    padding: 16px;
    box-sizing: border-box;
    div{
      position: relative;
    }
    ///deep/div:hover{
    //  background: #F4F6FB!important;
    //}
    .leftParagraphItem:hover{
      background: #F4F6FB!important;
      cursor: pointer;
    }
    /deep/img{
      max-width: 100%;
    }
  }
  .subsectionRight{
    width: 50%;
    flex: none;
    padding: 16px;
    box-sizing: border-box;
    height: 100%;
    .rightHeader{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .headerLeft{
        display: flex;
        align-items: center;
        .number{
          height: 20px;
          padding: 0 2px;
          background: #ECF1FF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366AFF;
          font-size: 12px;
          margin-left: 8px;
        }
      }
      .headerRight{
        display: flex;
        align-items: center;
        .ocrBtn{
          height: 28px;
          padding: 0 8px;
          background: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #A1B9FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366aff;
          cursor: pointer;
          font-size: 12px;
          margin-right: 10px;
          i{
            font-size: 12px;
          }
          .guoran-tongyichicun-shibieshuai{
            margin-right: 4px;
          }
          .el-icon-arrow-down{
            margin-left: 4px;
          }
        }
        .sort{
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-size: 12px;
          .sortTitle{
            display: flex;
            align-items: center;
            margin-right: 6px;
            .guoran-bangzhuzhongxin{
              margin-left: 4px;
              font-size: 12px;
            }
          }
        }
        .openBtn{
          height: 28px;
          padding: 0 8px;
          background: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #A1B9FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366aff;
          cursor: pointer;
          font-size: 12px;
          i{
            margin-right: 4px;
            font-size: 12px;
          }
        }
        .closeBtn{
          height: 28px;
          padding: 0 8px;
          background: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #A1B9FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #366aff;
          cursor: pointer;
          margin: 0 10px;
          font-size: 12px;
          i{
            margin-right: 4px;
            font-size: 12px;
          }
        }
        .saveBtn{
          height: 28px;
          padding: 0 8px;
          background: #366aff;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          cursor: pointer;
          font-size: 12px;
          i{
            margin-right: 4px;
            font-size: 12px;
          }
        }
      }
    }
    .splitParagraphs{
      height: calc(100% - 50px);
      overflow-y: scroll;
      padding-right: 10px;
      .paragraphItem{
        background: #F6F8FD;
        border-radius: 5px;
        padding: 8px 12px;
        margin-bottom: 12px;
        border: 1px solid #ffffff;
        .paragraphTitle{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .titleLeft{
            display: flex;
            align-items: center;
            overflow: hidden;
            .title{
              display: flex;
              align-items: center;
              flex: none;
              height: 21px;
              padding: 0 4px;
              background: #E3EBFF;
              margin-right: 8px;
            }
            .prompt{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .titleRight{
            display: flex;
            align-items: center;
            i{
              color: #366aff;
              cursor: pointer;
              margin-left: 14px;
              font-size: 14px;
            }
          }

        }
        .paragraphContainer{
          padding-top: 12px;
          .paragraph{
            margin-bottom: 4px;
          }
          .editParagraph{
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            .editHeader{
              padding: 8px 12px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .headerRight{
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: flex-end;
              }
              .guoran-tongyichicun-kaiqi1{
                margin: 0 10px;
                color: #366aff;
              }
              i{
                cursor:pointer;
                font-size: 14px;
                color: #999999;
              }
            }
            .editContainer{
              .tableHeader{
                display: flex;
                align-items: center;
                height: 40px;
                padding: 0 10px;
                background-color: #F0F4FF;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .tableHeaderTip{
                  margin-left: 6px;
                  font-size: 12px;
                  color: #616161;
                }
              }
            }
            .tableContainer{
              position: relative;
              .tablePopover{
                position: fixed;
                left: calc(50% - 370px);
                width: 400px;
                height: 400px;
                top: 0;
                z-index: 99;
                background-color: #ffffff;
              }
            }
            /deep/.el-textarea__inner{
              border: none;
            }
          }
          .originalParagraphs{
            background: #E3EBFF;
            border-radius: 5px;
            padding: 12px;
            margin-top: 12px;
            .originalTitle{
              margin-bottom: 12px;
              display: flex;
              align-items: center;
              .originalTip{
                color: #999999;
                font-size: 12px;
                margin-left: 6px;
              }
            }
            .originalParagraphItem{
              background: #F6F8FD;
              border-radius: 5px;
              padding: 6px;
              display: flex;
              justify-content: space-between;
              margin-bottom: 6px;
              border: 1px solid #F6F8FD;
              .originalLeft{
                display: flex;
                flex: 1;
                .ocr_text{
                  display: flex;
                  .ocr_icon{
                    font-size: 12px;
                    width: 80px;
                    flex: none;
                  }
                }
              }
              .guoran-tuodong{
                color: #366aff;
                margin-right: 6px;
                cursor: move;
                display: none;
              }
              .originalRight{
                display: none;
                .ocrIcon{
                  margin-left: 10px;
                  i{
                    //color: #366aff;
                    cursor:pointer;
                    font-size: 14px;
                  }
                  .guoran-OCR{
                    color: #366aff;
                  }
                }
                .delete_icon{
                  flex: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0 6px;
                  height: 20px;
                  background: #E4ECFF;
                  border-radius: 9px;
                  font-size: 12px;
                  color: #666666;
                  margin-left: 10px;
                }
              }
              .guoran-qita1{
                color: #366aff;
                margin-left: 12px;
                cursor: pointer;
              }
              /deep/td{
                border: 1pt solid #E0E6F7!important;
              }
              /deep/table{
                background: #ffffff;
                border: 1pt solid #E0E6F7!important;
              }
            }

            .originalParagraphItem:hover{
              border: 1px solid #A1B9FF;
              .guoran-tuodong{
                display: flex;
              }
              .originalRight{
                display: flex;
              }
              .guoran-qita1{
                display: flex;
              }
            }
          }
          /deep/.el-table__empty-text{
            line-height: 20px!important;
          }
        }
      }
    }
    .loading{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/img{
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .orcTips{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #616161;
    .tip1{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      img{
        margin-right: 6px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
.animation {
  animation-name: highlight;
  animation-duration: 4s;
}
.popoverContainer{
  .popoverBtn{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .same{
      border-radius: 10px;
      padding: 0px 6px;
      height: 24px;
      font-size: 12px;
      cursor:pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel{
      border: 1px solid #366aff;
      color: #366aff;
      background-color: #ffffff;
      margin-right: 6px;
    }
    .confirm{
      border: 1px solid #366aff;
      background-color: #366aff;
      color: #ffffff;
    }
  }
}
@keyframes highlight {
  0% {
    background: rgba(255, 136, 0, 0.3);
  }

  25% {
    background: rgba(255, 136, 0, 0.6);
  }

  50% {
    background: rgba(255, 136, 0, 0.3);
  }

  75% {
    background: rgba(255, 136, 0, 0.6);
  }

  100% {
    background: rgba(255, 136, 0, 0.3);
  }
}
</style>